import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <Layout>
      <Helmet>
        <title>edgurgel</title>
        <meta name="description" content="edgurgel's blog" />
      </Helmet>

      <div className="flex flex-col">
        <span className="text-gray-400">Posts:</span>
        {posts.map(post => (
          <div key={post.id} className="py-2">
            <article>
              <Link to={post.fields.slug}>
                <div>
                  <strong className="pr-2 hover:bg-gray-50 hover:text-blue-400">{post.frontmatter.title}</strong>
                </div>
              </Link>
              <div>
                <small>{post.frontmatter.date}</small>
              </div>
            </article>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPosts {
    blog: allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          author
        }
        excerpt
        id
      }
    }

    image: file(base: { eq: "eduardo.jpeg" }) {
      publicURL
    }
  }
`
